// components/PodcastCreationForm.js

import React, { useState } from 'react';

function PodcastCreationForm({ onSubmit, isCreating }) {
  const [url, setUrl] = useState('');
  const [languageLevel, setLanguageLevel] = useState('a2');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!url.trim()) {
      alert('Please provide a URL.');
      return;
    }
    console.log('Form submitting with level:', languageLevel); // Debug log
    onSubmit({ url, languageLevel });  // Pass both values
  };


  return (
    <div className='create-podcast-form p-10 mt-10 sm:mx-auto sm:w-full sm:max-w-xl rounded-md bg-white'>
      <h2 className='text-3xl font-bold'>Create New Podcast</h2>
      <form onSubmit={handleSubmit}>
        <input
          className='m-auto mt-10 shadow-md bg-transparent min-h-14 block w-full border-0 py-2 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          type='text'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder='Enter the URL of the article'
          disabled={isCreating}
        />
        <label className='block mb-1 text-sm text-slate-800 mt-10'>
          Select language level
        </label>
        <select
          value={languageLevel}
          onChange={(e) => setLanguageLevel(e.target.value)}
          className='w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer'
          disabled={isCreating}
        >
          <option value='a2'>A2</option>
          <option value='b1'>B1</option>
          <option value='b2'>B2</option>
          <option value='c1'>C1</option>
        </select>
        <button
          type='submit'
          disabled={isCreating}
          className='text-white m-auto mt-10 text-lg flex w-44 justify-center rounded-3xl bg-rose-400 px-3 py-2 my-10 font-semibold leading-6 shadow-sm hover:bg-rose-500 focus:outline-none'
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default PodcastCreationForm;
