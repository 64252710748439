// components/PodcastCard.js

import React, { useState, useEffect } from 'react';
import AudioPlayer from './AudioPlayer';
import Transcript from './Transcript';
import { ReactComponent as TranscriptIcon } from '../images/transcript.svg';
import { ReactComponent as FlipBackIcon } from '../images/flip_back.svg';
import { ReactComponent as ShareIcon } from '../images/share.svg';
import podcastDefaultImage from '../images/podcast.png';

const PodcastCardImagePlaceholder = () => {
    return (
      <div className='img-container'>
        <div className='img'></div>
      </div>
    );
  };
  
  const PodcastCardTextPlaceholder = () => {
    return (
      <div className='content'>
        <div className='stripe small-stripe'></div>
        <div className='stripe medium-stripe'></div>
        <div className='stripe long-stripe'></div>
      </div>
    );
  };

  
function PodcastCard({ podcast, isCreating = false, progressMessages = [], audioRef: propAudioRef, isPlayable = true, isReadyToPlay = false }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const localAudioRef = React.useRef(null);
  const audioRef = propAudioRef || localAudioRef;
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  // Check if this is a newly created podcast (being processed)
  const isNewPodcast = isCreating || progressMessages.length > 0;

  // For existing podcasts: always playable
  // For new podcasts: must be playable AND have s3_url
  const isPodcastPlayable = isNewPodcast
    ? (isPlayable && podcast.s3_url)
    : true;

  // Helper function to extract domain name from URL
  const getDomainFromUrl = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname.replace('www.', ''); // Remove 'www.' for brevity
    } catch (e) {
      return url;
    }
  };

  const languageLevel = podcast.language_level || 'a2';

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/shared/${podcast.objectID}`;
    
    try {
      await navigator.clipboard.writeText(shareUrl);
      const tooltip = document.createElement('div');
      tooltip.textContent = 'Link copied!';
      tooltip.style.cssText = `
        position: fixed;
        top: 20px;
        right: 20px;
        background: #333;
        color: white;
        padding: 8px 12px;
        border-radius: 4px;
        z-index: 1000;
      `;
      
      document.body.appendChild(tooltip);
      setTimeout(() => tooltip.remove(), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // Helper function to check URL validity
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (podcast.image_url && podcast.image_url.trim() !== '') {
      setShowPlaceholder(false);
    } else if (podcast.objectID) {
      setShowPlaceholder(false);
    }
  }, [podcast.image_url, podcast.objectID]);

  return (
    <>
      <div className='card-container w-full lg:w-72 rounded-md lg:m-auto lg:p-4 relative overflow-visible'>
        <div className={`card flip-card ${isFlipped ? 'is-flipped' : ''} overflow-visible`}>
          {/* Front Side */}
          <div className='card-face flip-card-face flip-card-front overflow-visible'>
            {!isPlayable ? (
              <PodcastCardImagePlaceholder />
            ) : (
              <>
                {showPlaceholder ? (
                  <PodcastCardImagePlaceholder />
                ) : (
                  <div className='h-36 overflow-hidden'>
                    {podcast.image_url ? (
                      <img src={podcast.image_url} alt={podcast.title} />
                    ) : (
                      <img src={podcastDefaultImage} alt="Default podcast" />
                    )}
                  </div>
                )}
              </>
            )}
            <div className='mt-4'>
              {!isPlayable ? (
                <PodcastCardTextPlaceholder />
              ) : (
                <>
                  <h3 className='font-bold mb-1'>{podcast.title || 'Untitled Podcast'}</h3>
                  <p className='mb-1 text-ellipsis overflow-hidden truncate'>
                    {podcast.description || 'No description available'}
                  </p>
                </>
              )}
              <div>
                <a
                  href={isValidUrl(podcast.text_url) ? podcast.text_url : '#'}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-slate-500 text-xs'
                >
                  {isValidUrl(podcast.text_url) 
                    ? getDomainFromUrl(podcast.text_url)
                    : 'URL not available'}
                </a>
              </div>
            </div>

            <div className='flex items-center justify-between mt-auto mb-3 overflow-visible'>
              <button
                onClick={handleShare}
                className='share-button p-2 hover:bg-gray-100 rounded-full'
                title="Share podcast"
              >
                <ShareIcon className="h-5 w-5" />
              </button>
              {/* Audio Player */}
              <AudioPlayer
                audioRef={audioRef}
                audioSrc={podcast.s3_url}
                isPlayable={isPodcastPlayable}
                isReadyToPlay={isReadyToPlay}
              />

              <button
                onClick={() => setIsFlipped(true)}
                className='flip-button p-2 hover:bg-gray-100 rounded-full'
                title="Show transcript"
              >
                <TranscriptIcon />
              </button>
            </div>

            {/* Language level badge */}
            <div
              className={`${languageLevel}-level uppercase font-bold text-white absolute top-2 right-2 rounded-br-md rounded-tl-md p-1 flex items-center justify-center w-8 h-8`}
            >
              {languageLevel}
            </div>

            {/* Progress messages */}
            {isCreating && (
              <p className='text-xs text-slate-500 flex items-center justify-center'>
                {progressMessages.length > 0
                  ? progressMessages[progressMessages.length - 1]
                  : ''}
              </p>
            )}
          </div>

          {/* Back Side */}
          <div className='card-face flip-card-face flip-card-back'>
            {/* Transcript - only load when flipped */}
            {isFlipped && (
              <Transcript
                podcastId={podcast.objectID}
                audioRef={audioRef}
              />
            )}

            {/* Flip back button */}
            <button
              onClick={() => setIsFlipped(false)}
              className='flip-button absolute bottom-2 right-2'
            >
              <FlipBackIcon />
            </button>

            {/* Language level badge */}
            <div
              className={`${languageLevel}-level uppercase font-bold text-white absolute top-2 right-2 rounded-br-md rounded-tl-md p-1 flex items-center justify-center w-8 h-8`}
            >
              {languageLevel}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PodcastCard;
