// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PodcastStreamer from './PodcastStreamer';
import SharedPodcast from './components/SharedPodcast';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PodcastStreamer />} />
        <Route path="/shared/:podcastId" element={<SharedPodcast />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
