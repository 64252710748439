import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import PodcastCard from './PodcastCard';
import Header from './Header';

function SharedPodcast() {
  const { podcastId } = useParams();
  const [podcast, setPodcast] = useState(null);
  const [error, setError] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchPodcastData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get_podcast_metadata/${podcastId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch podcast data');
        }

        const data = await response.json();
        setPodcast(data);
      } catch (error) {
        setError(error.message);
      }
    };

    if (podcastId) {
      fetchPodcastData();
    }
  }, [podcastId, API_BASE_URL]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header />
        <div className="max-w-2xl mx-auto mt-8 p-4">
          <h2 className="text-xl font-bold text-red-500 mb-4">
            Invalid or missing podcast data
          </h2>
          <Link
            to="/"
            className="text-rose-400 hover:text-rose-500"
          >
            ← Back to home
          </Link>
        </div>
      </div>
    );
  }

  if (!podcast) {
    return (
      <div className="min-h-screen bg-gray-100">
        <Header />
        <div className="max-w-2xl mx-auto mt-8 p-4">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <div className="max-w-2xl mx-auto mt-8 p-4">
        <Link
          to="/"
          className="text-rose-400 hover:text-rose-500"
        >
          ← Back to home
        </Link>
        <PodcastCard
          podcast={podcast}
          isPlayable={true}
          isReadyToPlay={true}
        />
      </div>
    </div>
  );
}

export default SharedPodcast; 