// components/SearchComponent.js

import React from 'react';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { Index, InstantSearch, SearchBox, useHits, Pagination, Configure } from 'react-instantsearch';
import 'instantsearch.css/themes/satellite.css';
import PodcastCard from './PodcastCard';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_ID,
  process.env.REACT_APP_ALGOLIA_KEY
);


// Custom Hits Component using useHits
const CustomHits = ({ newPodcast, isCreating, progressMessages, audioRef, isPlayable, isReadyToPlay }) => {
  const { hits } = useHits();

  return (
    <div className="ais-Hits-list">
      {/* Render newPodcast as the first item */}
      {newPodcast && (
        <div className="ais-Hits-item">
          <PodcastCard
            podcast={newPodcast}
            isCreating={isCreating}
            progressMessages={progressMessages}
            audioRef={audioRef}
            isPlayable={isPlayable}
            isReadyToPlay={isReadyToPlay}
          />
        </div>
      )}
      {/* Render existing hits */}
      {hits.map((hit) => (
        <div className="ais-Hits-item" key={hit.objectID}>
          <PodcastCard podcast={hit} />
        </div>
      ))}
    </div>
  );
};

function SearchComponent({
  newPodcast,
  isCreating,
  progressMessages,
  audioRef,
  isPlayable,
}) {
  return (
    <InstantSearch searchClient={searchClient} indexName="podcasts">
      <div className="search-container">
        {/* Configure hits per page */}
        <Configure hitsPerPage={20} />
        
        <SearchBox
          placeholder="Search podcasts by title, description, or language level..."
        />

        <Index indexName="podcasts">
          <CustomHits
            newPodcast={newPodcast}
            isCreating={isCreating}
            progressMessages={progressMessages}
            audioRef={audioRef}
            isPlayable={isPlayable}
          />
          
          {/* Add Pagination component */}
          <div className="pagination-container">
            <Pagination
              padding={2}
              showFirst={true}
              showPrevious={true}
              showNext={true}
              showLast={true}
              classNames={{
                root: 'pagination',
                list: 'pagination-list',
                item: 'pagination-item',
                selectedItem: 'pagination-item-selected',
                disabledItem: 'pagination-item-disabled',
                firstItem: 'pagination-item-first',
                lastItem: 'pagination-item-last',
                previousItem: 'pagination-item-previous',
                nextItem: 'pagination-item-next',
              }}
            />
          </div>
        </Index>
      </div>
    </InstantSearch>
  );
}

export default SearchComponent;