import React from "react";

const Modal = ({ isModalOpen, onClose, children }) => {
  if (isModalOpen !== true) {
    return null;
  }

  return (
    <section className="modal">
      <div className="exit-icon text-end">
        <span
          className="close-icon text-white dark:text-white"
          onClick={onClose}
        >
          X
        </span>
      </div>
      {children}
    </section>
  );
};

export default Modal;
