// components/AudioPlayer.js

import React, { useState, useEffect } from 'react';
import { ReactComponent as Play } from '../images/play.svg';
import { ReactComponent as Pause } from '../images/pause.svg';

function AudioPlayer({ audioRef, audioSrc, isPlayable, isReadyToPlay }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const [disableIncrement] = useState(false);
  const [shouldButtonPulse, setShouldButtonPulse] = useState(isReadyToPlay);
  const [showSpeed, setShowSpeed] = useState(false);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed, audioRef]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      setIsPlaying(false);  // Reset to play icon when audio ends
    };

    if (audio) {
      audio.addEventListener('ended', handleEnded);
    }

    // Cleanup
    return () => {
      if (audio) {
        audio.removeEventListener('ended', handleEnded);
      }
    };
  }, [audioRef]);

  useEffect(() => {
    let timer;
    if (showSpeed) {
      timer = setTimeout(() => {
        setShowSpeed(false);
      }, 2000); // 2 seconds
    }
    return () => clearTimeout(timer);
  }, [showSpeed]);

  const togglePlay = () => {
    setShouldButtonPulse(false);
    if (!isPlayable) {
      return;
    }
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  const changeSpeed = (increment) => {
    let newSpeed = playbackSpeed + increment;
    newSpeed = Math.max(0.5, Math.min(2, newSpeed));
    if (newSpeed > 1.1) {
      newSpeed = 1.1;
    } else if (newSpeed < 0.8) {
      newSpeed = 0.8;
    }
    setPlaybackSpeed(newSpeed);
    setShowSpeed(true); // Show speed when buttons are pressed
  };

  return (
    <>
      <div className='flex items-center justify-center'>
        <button
          title="Decrease playback speed"
          onClick={() => changeSpeed(-0.1)}
          disabled={!isPlayable}
          className={`text-sm rounded-full bg-slate-200 w-5 h-5 ${
            isPlayable
              ? 'cursor-pointer'
              : 'cursor-not-allowed text-gray-400'
          }`}
        >
          <span>-</span>
        </button>
        <div className='relative mx-1'>
          <button
            onClick={togglePlay}
            disabled={!isPlayable}
            className={`rounded-full mx-2 w-8 h-8 flex items-center justify-center ${
              isPlayable
                ? 'hover:scale-105 cursor-pointer text-black'
                : 'cursor-not-allowed text-gray-200 opacity-40'
            }`}
          >
            <div className={`rounded-full p-2 bg-rose-400 flex item-center justify-center button-play ${shouldButtonPulse && ('pulse')}`}>
              {isPlaying ? <Pause /> : <Play />}
            </div>
          </button>
          {showSpeed && (
            <div
              className='text-xs absolute left-1/2 -translate-x-1/2 mt-1 transition-opacity duration-1000 opacity-100'
            >
              {playbackSpeed === 1 ? '1x' : `${playbackSpeed.toFixed(1)}x`}
            </div>
          )}
        </div>
        <button
          title="Increase playback speed"
          onClick={() => changeSpeed(0.1)}
          disabled={!isPlayable || disableIncrement}
          className={`text-sm rounded-full bg-slate-200 w-5 h-5 ${
            isPlayable
              ? 'cursor-pointer'
              : 'cursor-not-allowed text-gray-400'
          }`}
        >
          <span>+</span>
        </button>
      </div>
      <audio ref={audioRef} src={audioSrc} style={{ display: 'none' }}></audio>
    </>
  );
}

export default AudioPlayer;
