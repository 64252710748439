// PodcastStreamer.js

import React, {useRef, useState, useEffect } from 'react';
import Header from './components/Header';
import PodcastCreationForm from './components/PodcastCreationForm';
import SearchComponent from './components/SearchComponent';
import Modal from './Modal';
import { blake2b } from 'blakejs';
import ReactGA from "react-ga4";

function PodcastStreamer() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  }, []);

  // Essential state for podcast creation and streaming
  const [isCreating, setIsCreating] = useState(false);
  const [newPodcast, setNewPodcast] = useState(null);
  const [progressMessages, setProgressMessages] = useState([]);
  const [responseMetadata, setResponseMetadata] = useState(null);
  const [isPlayable, setIsPlayable] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReadyToPlay, setIsReadyToPlay] = useState(false);


  // Refs and MediaSource state
  const audioRef = useRef(null);
  const [mediaSource, setMediaSource] = useState(null);
  const [sourceBuffer, setSourceBuffer] = useState(null);

  // Constants
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const language = 'German';
  const maleName = 'Chris';
  const femaleName = 'Mila';

  // Function to compute BLAKE2b hash (16 chars)
  const computeHash = async (text) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    
    // Create BLAKE2b hash and get first 8 bytes (16 hex chars)
    const fullHash = blake2b(data, null, 8);
    // Convert to hex string
    return Array.from(new Uint8Array(fullHash))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  };

  const startSSE = (hashId) => {
    const eventSource = new EventSource(
      `${API_BASE_URL}/generate_podcast_tracker?hash_id=${hashId}`
    );

    eventSource.addEventListener('podcastStatus', (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Podcast status:', data);
        setProgressMessages((prevMessages) => [...prevMessages, data.message]);

        if (data.message === 'Ready to play') {
          setIsPlayable(true);
        } else if (data.message === "Completed") {
          eventSource.close();
        } else if (data.message.startsWith('Error')) {
          eventSource.close();
          alert(data.message);
          setIsProcessing(false);
          setIsPlayable(false);
        }
      } catch (error) {
        console.error('Error parsing podcastStatus event:', error);
      }
    });

    eventSource.addEventListener('podcastMetadata', (event) => {
      try {
        const data = JSON.parse(event.data);
        
        // Define fallback values
        const processedData = {
          ...data,
          image_url: data.image_url || '/podcast.png', // Add your placeholder image path
          title: data.title || 'No title available'
        };

        // Update newPodcast with the processed metadata
        setNewPodcast((prevPodcast) => ({
          ...prevPodcast,
          ...processedData,
          objectID: data.hash_id || prevPodcast.objectID,
        }));

        setResponseMetadata(processedData);
      } catch (error) {
        console.error('Error parsing podcastMetadata event:', error);
      }
    });

    eventSource.onerror = (err) => {
      // console.error('SSE error:', err);
      eventSource.close();
      setIsProcessing(false);
    };

    return eventSource;
  };

  const startStreaming = async (formUrl, languageLevel) => {
    try {
      const response = await fetch(`${API_BASE_URL}/generate_podcast`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          text_url: formUrl,
          language: language,
          male_name: maleName,
          female_name: femaleName,
          language_level: languageLevel,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      console.log('Stream started, waiting for first chunk...');

      const reader = response.body.getReader();

      const { done, value } = await reader.read();
      if (done) {
        throw new Error('No data received from the stream.');
      }

      console.log('First chunk received:', value);
      setIsPlayable(true);  // Enable play button when first chunk arrives
      setIsReadyToPlay(true);

      const newMediaSource = new MediaSource();
      setMediaSource(newMediaSource);
      audioRef.current.src = URL.createObjectURL(newMediaSource);

      newMediaSource.addEventListener('sourceopen', () => {
        console.log('MediaSource opened, setting up SourceBuffer...');
        const mimeCodec = 'audio/mpeg'; // Adjust if necessary
        const sb = newMediaSource.addSourceBuffer(mimeCodec);
        setSourceBuffer(sb);

        let queue = [value];
        let isAppending = false;
        let isReading = true;
        let isEnded = false;

        const processQueue = () => {
          if (isAppending || queue.length === 0 || isEnded) {
            return;
          }
          isAppending = true;
          const chunk = queue.shift();
          console.log('Appending chunk:', chunk);
          try {
            sb.appendBuffer(chunk);
          } catch (error) {
            console.error('Error during appendBuffer:', error);
          }
        };

        sb.addEventListener('updateend', () => {
          isAppending = false;
          // Removed setIsPlayable(true) here to rely on SSE event
          if (queue.length > 0) {
            processQueue();
          } else if (!isReading && !isEnded) {
            if (newMediaSource.readyState === 'open') {
              console.log('Ending stream...');
              newMediaSource.endOfStream();
              isEnded = true;
            }
          }
        });

        const readStream = async () => {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              console.log('Stream finished.');
              isReading = false;
              if (!sb.updating && queue.length === 0 && !isEnded) {
                if (newMediaSource.readyState === 'open') {
                  console.log('Ending stream (final)...');
                  newMediaSource.endOfStream();
                  isEnded = true;
                }
              }
              break;
            }
            queue.push(value);
            processQueue();
          }
        };

        processQueue();
        readStream();
      });
    } catch (error) {
      console.error('Error fetching audio stream:', error);
      alert(`Error: ${error.message}`);
      setIsProcessing(false);
      setIsPlayable(false);  // Disable play button on error
      setIsReadyToPlay(false);
    }
  };


  // Function to handle podcast creation submission
  const handlePodcastCreation = async ({ url: formUrl, languageLevel }) => {
    if (!formUrl.trim()) {
      alert('Please provide a URL.');
      return;
    }

    // Track podcast creation attempt
    ReactGA.event({
      category: "Podcast",
      action: "Creation Attempted",
      label: languageLevel
    });

    if (!isProcessing) {
      setIsCreating(true);
      setIsProcessing(true);
      setProgressMessages([]);

      const hashInput = `${formUrl}|${language}|${maleName}|${femaleName}|${languageLevel}`;
      const hashId = await computeHash(hashInput);

      setNewPodcast({
        title: null,
        description: null,
        image_url: null,
        text_url: formUrl,
        language_level: languageLevel,
        s3_url: null,
        objectID: hashId,
      });

      console.log("Starting SSE with the following hashId:", hashId);
      startSSE(hashId);

      try {
        await startStreaming(formUrl, languageLevel);
        // Track successful podcast creation
        ReactGA.event({
          category: "Podcast",
          action: "Creation Successful",
          label: languageLevel
        });
      } catch (error) {
        // Track failed podcast creation
        ReactGA.event({
          category: "Podcast",
          action: "Creation Failed",
        });
      }
    }
  };

  return (
    <>
      <Header />

      {/* Create New Podcast button */}
      {!isCreating && !newPodcast && (
        <div className='px-10 pb-4'>
          <button
            onClick={() => setIsModalOpen(true)}
            className='relative lg:absolute mt-8 lg:mt-0 lg:right-10 lg:top-16 text-white m-auto text-lg flex w-full lg:w-56 justify-center rounded-3xl bg-rose-400 px-3 py-3 font-semibold leading-6 dark:text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Create new podcast
          </button>
        </div>
      )}

      {/* Modal with PodcastCreationForm */}
      <Modal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <PodcastCreationForm
          onSubmit={(formData) => {
            handlePodcastCreation(formData);
            setIsModalOpen(false);
          }}
          isCreating={isCreating}
        />
      </Modal>

      {/* SearchComponent */}
      <div className='relative mt-4 lg:mt-24 md:mx-auto max-w-screen-lg p-10 pt-2 lg:pt-10'>
        <SearchComponent
          newPodcast={newPodcast || responseMetadata || null}
          isCreating={isCreating}
          progressMessages={progressMessages}
          audioRef={audioRef}
          isPlayable={isPlayable}
          isReadyToPlay={isReadyToPlay}
        />
      </div>
    </>
  );
}

export default PodcastStreamer;
