// components/Transcript.js

import React, { useState, useEffect, useRef } from 'react';

function Transcript({ podcastId, audioRef }) {  
  
  const [transcriptData, setTranscriptData] = useState(null);
  const [allWords, setAllWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(null);
  const [transcriptError, setTranscriptError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const wordRefs = useRef({});
  const MAX_RETRIES = 40;
  const RETRY_INTERVAL = 10000; // 10 seconds

  // total time to wait for transcript to be generated is 400 seconds (6.6 minutes)
  // increase this if we ever have longer podcasts

  // Fetch transcript data with retry logic
  useEffect(() => {
    let timeoutId;

    const fetchTranscript = async () => {
      if (!podcastId) {
        console.log('No podcastId yet, waiting...');
        return;
      }

      try {
        const url = `https://d6i8.c16.e2-5.dev/podcast-transcripts/${podcastId}.json`;
        console.log('Attempting to fetch from:', url);

        const response = await fetch(url);
        console.log('Response status:', response.status);
        console.log('Response headers:', Object.fromEntries(response.headers));

        if (!response.ok) {
          if (response.status === 404) {
            if (retryCount < MAX_RETRIES) {
              console.log(`Transcript not found, retry ${retryCount + 1} of ${MAX_RETRIES} in ${RETRY_INTERVAL/1000}s`);
              timeoutId = setTimeout(() => {
                setRetryCount(prev => prev + 1);
              }, RETRY_INTERVAL);
              setTranscriptError(true);
            } else {
              console.log('Max retries reached, giving up');
              setTranscriptError(true);
            }
            return;
          }
          const errorText = await response.text();
          console.error('Error response body:', errorText);
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        const processedData = processTranscriptData(data);
        setTranscriptData(processedData);
        setTranscriptError(false);
        setRetryCount(0);

      } catch (error) {
        console.error('Fetch error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack
        });
        if (retryCount < MAX_RETRIES) {
          timeoutId = setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, RETRY_INTERVAL);
        } else {
          setTranscriptError(true);
        }
      }
    };

    fetchTranscript();

    // Cleanup timeout on unmount or when podcastId changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [podcastId, retryCount]);

  // Process transcript data
  const processTranscriptData = (data) => {
    const { conversation, timestamps } = data;
    const processedData = [];
    let timestampIndex = 0;
    let globalIndex = 0;

    conversation.forEach((turn) => {
      const speaker = turn.speaker;
      const text = turn.text;
      const words = text.split(/\s+/); // Split on whitespace
      const wordObjects = [];

      words.forEach((word) => {
        if (timestampIndex < timestamps.length) {
          const timestamp = timestamps[timestampIndex];
          wordObjects.push({
            text: word,
            start: timestamp.start,
            end: timestamp.end,
            hasTiming: timestamp.start !== null && timestamp.end !== null,
            globalIndex: globalIndex,
          });
          timestampIndex++;
        } else {
          // In case there are more words than timestamps
          wordObjects.push({
            text: word,
            start: null,
            end: null,
            hasTiming: false,
            globalIndex: globalIndex,
          });
        }
        globalIndex++;
      });

      processedData.push({
        speaker: speaker,
        words: wordObjects,
      });
    });

    return processedData;
  };

  // Scroll highlighting into view
  useEffect(() => {
    if (currentWordIndex !== null) {
      const currentWordElement = wordRefs.current[currentWordIndex];
      if (currentWordElement) {
        currentWordElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [currentWordIndex]);

  // Maintain a flat list of all words
  useEffect(() => {
    if (transcriptData) {
      const flattenedWords = transcriptData.reduce((acc, turn) => {
        return acc.concat(turn.words);
      }, []);
      setAllWords(flattenedWords);
    }
  }, [transcriptData]);

  // Effect to handle word highlighting
  useEffect(() => {
    let rafId;

    const updateCurrentWord = () => {
      if (
        audioRef.current &&
        allWords &&
        allWords.length > 0
      ) {
        const currentTime = audioRef.current.currentTime;

        // Find the index of the word that is currently being spoken
        let index = -1;
        for (let i = 0; i < allWords.length; i++) {
          const word = allWords[i];
          if (word.hasTiming) {
            if (currentTime >= word.start && currentTime < word.end) {
              index = word.globalIndex;
              break;
            }
          }
        }

        if (index !== -1 && index !== currentWordIndex) {
          setCurrentWordIndex(index);
        } else if (index === -1 && currentWordIndex !== null) {
          setCurrentWordIndex(null);
        }

        rafId = requestAnimationFrame(updateCurrentWord);
      }
    };

    rafId = requestAnimationFrame(updateCurrentWord);

    return () => {
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
    };
  }, [currentWordIndex, allWords, audioRef]);

  if (transcriptError) {
    return <p>Transcript will appear here shortly after the podcast generation has been completed.</p>;
  }

  if (!transcriptData) {
    return <p>Loading transcript...</p>;
  }

  return (
    <div className='transcript'>
      {transcriptData.map((turn, turnIndex) => (
        <div key={turnIndex}>
          <p className='speaker-name'>{turn.speaker}:</p>
          <p className='turn-text'>
            {turn.words.map((word) => {
              const isHighlighted = word.globalIndex === currentWordIndex;
              return (
                <span
                  key={word.globalIndex}
                  ref={(el) => {
                    wordRefs.current[word.globalIndex] = el;
                  }}
                  className={isHighlighted ? 'highlight' : ''}
                  style={{ marginRight: '2px' }}
                >
                  {word.text}{' '}
                </span>
              );
            })}
          </p>
        </div>
      ))}
    </div>    
  );
}

export default Transcript;
